import React, { useCallback, useState } from 'react'
import { Row, Col, Button, Label, Input, Card } from 'reactstrap'
import GetAllUsersAndRolesByStoreService from '../../../../../../../Service/OPSServices/StoreHealthServices/GetAllUsersAndRolesByStoreService'
import { connect } from "react-redux";
import { divide } from 'lodash';
import ReactTable from 'react-table';
import AddNewRolesForStoresService from '../../../../../../../Service/OPSServices/StoreHealthServices/AddNewRolesForStoresService';
import CustomModal from '../../../../../../Components/Modals/CustomModal';
import UpdateRolesForStoresService from '../../../../../../../Service/OPSServices/StoreHealthServices/UpdateRolesForStoresService';

function AddStoreRoles(props) {
    const [teamMembers, setTeamMembers] = useState()
    const [userRoles, setUserRoles] = useState()
    const [memberRoles, setMemberRoles] = useState()
    const [addNewUser, setAddNewUser] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [serachText, setSerachText] = useState('')
    const [confirmationPopu, setConfirmationPopu] = useState('')
    const [userRolesData,setUserRolesData]=useState('')
    const [submitpopup, setsubmitpopup] = useState(false)

    useState(() => {
        // setUserData(props.storeRoles)
        let data = {
            integerId: props.integerId
        }
        GetAllUsersAndRolesByStoreService(props.token, data).then((res) => {
            // console.log('res')
            if (res) {
                console.log(res)
                setUserRoles(res.userList)
                setTeamMembers(res.teamMembers)
                setMemberRoles(res.roles)
            }
        })
    })
    const handleTeamMembers = (value) => {
        setAddNewUser(value); // Update the selected user ID
    };

    const handleRoleChange = (value) => {
        setSelectedRole(value); // Update the selected role
    };
    const handleRoleSubmit = () => {
        let data = {
            integerId: props.integerId,
            userId: addNewUser,
            roleId: selectedRole,
        };
        console.log(data)
        //   return true
        AddNewRolesForStoresService(props.token, data).then((res) => {
            if (res) {
                console.log(res)
                props.setStoreRolesPopup(false)
                setUserRoles(res.userList)
                setsubmitpopup(false)
            }
        })
    };
    const handleConfirmation = (value) => {
        console.log(value)
        setUserRolesData(value)
        setConfirmationPopu(true)

    }
    const columns = [
        {
            Header: 'Permitions',
            accessor: 'isActive',
            Cell: (cellInfo) => (
                <div className='d-flex justify-content-center w-100'>
                    <input
                        type='checkbox'
                        className=''
                        // value={userRoles.isActive}
                        checked={cellInfo.value ? true : false}
                        onClick={() => handleConfirmation(cellInfo.original)}
                    />
                </div>

            ),
        },
        {
            Header: 'Name',
            accessor: 'name',
            Cell: (cellInfo) => (
                <div className='d-flex justify-content-center w-100'><p>{cellInfo.value}</p></div>
            ),
        },
        {
            Header: 'Roles',
            accessor: 'role',
            Cell: (cellInfo) => (
                <div className='d-flex justify-content-center w-100'><p>{cellInfo.value}</p></div>
            ),
        },
        {
            Header: 'SimpliUserId',
            accessor: 'simpliUserId',
            Cell: (cellInfo) => (
                <div className='d-flex justify-content-center w-100'><p>{cellInfo.value}</p></div>
            )
        }
    ]
    const filterUserRoles = userRoles && userRoles.filter((row) => {
        const name = (row.name || '').toLowerCase()
        const roles = (row.role || '').toLowerCase()
        const SimpliUserId = (row.simpliUserId || '').toString().toLowerCase()
        return name.includes(serachText.toLowerCase()) || SimpliUserId.includes(serachText.toString().toLowerCase()) || roles.includes(serachText.toLowerCase())
    })
    const handleChange = (e) => {
        setSerachText(e.target.value)
    }
    const handleChangePermitions=()=>{
        let data={
            integerId: props.integerId,
            name:userRolesData.name,
            role:userRolesData.role,
            roleId:userRolesData.roleId,
            simpliUserId:userRolesData.simpliUserId
        }
        UpdateRolesForStoresService(props.token,data).then((res)=>{
            if(res){
                console.log('res')
                console.log(res)
                setsubmitpopup(true)
            }
        })

    }
    return (
        <>
            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={6} className=' d-flex justify-content-between'>
                            <h5><b>User History</b></h5></Col>
                            <Col md={4} className='d-flex justify-content-end'>
                            <Input
                                type='text'
                                placeholder='Search Text'
                                onChange={handleChange}
                                className='w-75 mr-3'
                            />
                             </Col>
                            <Col md={2}>
                             <Button
                                onClick={() => setsubmitpopup(true)} className='btn-background-color'
                            >Add User
                            </Button> </Col>

                       
                        <Col md={12} className='mt-2'>
                            <ReactTable
                                data={filterUserRoles}
                                columns={columns}
                                className="-fixed -highlight -striped"
                                getTheadProps={() => {
                                    return {
                                        style: {
                                            overflowY: "hidden",
                                            background: "#B1FA63",
                                            // color: "#243837",
                                        },
                                    };
                                }}
                                defaultPageSize={5}
                                noDataText={"No Data Found."}
                            // defaultPageSize={5}

                            />
                        </Col>
                    </Row>

                </Col>
            </Row>
            <CustomModal
                header={'Confirm PopUp'}
                isOpen={confirmationPopu}
                toggle={() => { setConfirmationPopu(!confirmationPopu); }}
                size={"md"}
                className='curve-popup'
            >
                <Card className='curve-popup p-3'>
                    <Row className=''>
                        <Col md={12} className='mt-2'>
                        <h5><b>Are you sure want to Confirm</b></h5>
                         </Col>
                         <Col md={12} className='d-flex justify-content-end'>
                         <Button className='btn-background-color mr-2' onClick={()=>handleChangePermitions()}>Confirm</Button>
                         <Button onClick={()=>setConfirmationPopu(false)}>Cancel</Button>
                         </Col>
                    </Row>
                </Card>
            </CustomModal>
           

            <CustomModal
                header={'Add User'}
                isOpen={submitpopup}
                toggle={() => { setsubmitpopup(!submitpopup); }}
                size={"sm"}
                className='curve-popup'
            >
                <Card className='curve-popup p-3'>
                <Row>
               
                        <Col md={12} className='mb-2 d-flex'>
                           
                            <select
                                id="assignedTo"
                                name="assignedTo"
                                onChange={(e) => handleTeamMembers(e.target.value)} // Update user selection
                                className="form-control"
                            >
                                <option value="">Select User</option>
                                {teamMembers &&
                                    teamMembers.map((member) => (
                                        <option key={member.employeeId} value={member.employeeId}>
                                            {member.employeeName}
                                        </option>
                                    ))}
                            </select>
                        </Col>
                        <Col md={12} className='mb-2 d-flex'>
                           
                            <select
                                className="form-control"
                                onChange={(e) => handleRoleChange(e.target.value)} // Update role selection
                            >
                                <option value="">Select Role</option>
                                {memberRoles &&
                                    memberRoles.map((role) => (
                                        <option key={role.Account_Types_Id} value={role.id}>
                                            {role.Account_Types_Name}
                                        </option>
                                    ))}
                            </select>
                        </Col>
                        <Col md={12}className='d-flex justify-content-center'>
                            <Button
                                onClick={() => handleRoleSubmit()} className='btn-background-color '
                            >Submit
                            </Button></Col>
                           
                    </Row>

               
                </Card>
            </CustomModal>


        </>



    )
}
const mapStateToProps = (state) => {
    return {
        token: state.loggedUser.token,
        accessLevel: state.loggedUser.accessLevel,
        status: state.loggedUser.status,
        error: state.loggedUser.error,
        adProfiles: state.accountOverview.landing.adProfiles,
    };
};

export default connect(mapStateToProps)(AddStoreRoles);
