import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import { Nav, NavLink, Button } from "reactstrap";

import urls from '../../../../Utils/URLs';
import HeaderLogo from "./HeaderLogo";
import CreditBalanceService from "../../../../Service/PaymentServices/Credits/CreditBalanceService";
import Can, { filterByPermission } from "../../../Components/Can";
import UserProfileDropdown from "./UserProfileDropdown";
import CustomerPortalService from "../../../../Service/PaymentServices/CustomerPortalService";
import AppSumoTiersModal from "../../Components/Modals/AppSumoTiersModal";
import { appSumoPlans } from "../../../../Utils/AppSumoPlans";
import GetUserProfileDataService from "../../../../Service/DashboardServices/UserProfileServices/ProfileDataServices/GetUserProfileDataService";



function DashboardHeader(props) {

    let location = useLocation();
    let history = useHistory();

    const [isAppSumoOpen, setAppSumoOpen] = useState(false);

    const isActive = path => {
        let currentPath = "/" + location.pathname.split("/")[1];
        return currentPath === path;
    }
    // useEffect(() => {
    //     if (props.logged) {
    //         props.dispatch(CreditBalanceService(props.token));
    //     }
    // });
    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        companyName: "",
    });
    useEffect(() => {
        GetUserProfileDataService(props.token).then((response) => {
            if (response) {
                // console.log(response);
                setUserData({
                    firstName: response.firstName,
                    lastName: response.lastName,
                    email: response.email,
                    phone: response.phone,
                    companyName: response.companyName,
                });
            }
        });
    }, []);

    const launchpadCount = (numCart, numReady) => {
        if (numCart > 0 && numReady > 0) {
            return `${numCart} in cart, ${numReady} ready to launch`;
        } else if (numCart > 0) {
            return `${numCart} in cart`;
        } else {
            return `${props.numReady} ready to launch`;
        }
    }

    const handleCustomerPortalRedirect = async () => {
        const portalURL = await CustomerPortalService(props.token);
        if (portalURL) {
            window.open(`${portalURL}`, "_self")
        }
    }

    let userDropdownButtons = [
        // {
        //     perform: "credits:view",
        //     yes: {
        //         name: `Credits (${props.creditBalance})`,
        //         action: () => history.push(urls.CREDITS_PURCHASE_PAGE_URL),
        //     },
        // },
        {
            perform: "profile:userProfile",
            yes: {
                name: "My Profile",
                action: () => history.push(urls.USER_PROFILE_DATA_URL),
            },
        },
        {
            perform: "profile:authorization",
            yes: {
                name: "Authorization",
                action: () => history.push(urls.USER_PROFILE_AUTH_URL),
            },
        },

        // {
        //     perform: "profile:addKeywordstracking",
        //     yes: {
        //         name: "Add Keywords Tracking",
        //         action: () => history.push(urls.USER_PROFILE_ADD_KEYWORDS_TRACKING),
        //     },
        // },
        // {
        //     perform: "profile:spapiAuth",
        //     yes: {
        //         name: "Seller Partner Data",
        //         action: () => history.push(urls.OPS_SELLER_PARTNER_DATA_URL),
        //     },
        // },
        // {
        //     perform: "profile:adApiAuth",
        //     yes: {
        //         name: "Advertising Reports",
        //         action: () => history.push(urls.OPS_ADVERTISING_DATA_URL),
        //     },
        // },
        // {
        //     perform: "payment:customerPortal",
        //     yes: {
        //         name: "Subscriptions & Billing",
        //         action: () => handleCustomerPortalRedirect()
        //     },
        // },

        {
            perform: "accounts:view",
            yes: {
                name: 'User Directory',
                action: () => history.push(urls.USER_PROFILE_DIRECTORY_URL),
            },
        },
        {
            perform: "subAccounts:view",
            yes: {
                name: 'Sub-Accounts',
                action: () => history.push(urls.USER_PROFILE_SUB_ACCOUNTS_URL),
            },
        },
        {
            perform: "contentAgencies:view",
            yes: {
                name: 'Content Agencies',
                action: () => history.push(urls.USER_PROFILE_CONTENT_AGENCIES_URL),
            },
        },
        {
            perform: "leadGeneration:view",
            yes: {
                name: 'Lead Gen: Quick Search',
                action: () => history.push(urls.USER_PROFILE_LEAD_GEN_URL),
            },
        },
        // {
        //     perform: 'ops:view',
        //     yes: {
        //         name: 'Operations',
        //         action: () => history.push(urls.BASE_OPS_URL),
        //     }
        // },
        {
            perform: "profile:logout",
            yes: {
                name: "Logout",
                action: () => props.logout(),
            },
        },
    ];

    userDropdownButtons = filterByPermission(userDropdownButtons, props.accessLevel);

    return (
        <Fragment>
            <nav className="app-header  header-text-light header-shadow">
                {/* bg-brand-blue-darker */}
                <HeaderLogo />
                <div className="app-header__content">
                    <div className="app-header-left">
                        <Nav pills style={{ marginBottom: 0, height: '80%' }}>
                            <NavLink
                                tag={Link}
                                to={urls.STORE_OVERVIEW_MY_STORES_URL}
                                active={isActive(urls.BASE_STORE_OVERVIEW_URL)}
                            >
                                Store Overview
                            </NavLink>
                            {
                                // props.globalPermissions.HeaderMenu_TicketsList &&
                                <Can
                                    role={props.accessLevel}
                                    perform={"home:simple"}
                                    yes={() => (
                                        <NavLink
                                            tag={Link}
                                            to={urls.TROUBLE_TICKET_TICKET_LIST_URL}
                                            active={isActive(urls.BASE_TROUBLE_TICKET_URL)}
                                        >
                                            Ticket List
                                        </NavLink>
                                    )}
                                />
                            }

                            <Can
                                role={props.accessLevel}
                                perform={"campaigns:create"}
                                yes={() => (
                                    <NavLink
                                        tag={Link}
                                        to={urls.CREATE_CAMPAIGN_INFO_URL}
                                        active={isActive(urls.BASE_CREATE_CAMPAIGN_URL)}
                                    >
                                        Create Campaign
                                    </NavLink>
                                )}
                            />
                            <Can
                                role={props.accessLevel}
                                perform={"campaigns:launch"}
                                yes={() => (
                                    <NavLink
                                        tag={Link}
                                        to={urls.LAUNCHPAD_READY_CAMPAIGNS_URL}
                                        active={isActive(urls.BASE_LAUNCHPAD_URL)}
                                    >
                                        Launchpad ({launchpadCount(props.numCart, props.numReady)})
                                    </NavLink>
                                )}
                            />
                            <Can
                                role={props.accessLevel}
                                perform={"project:view"}
                                yes={() => (
                                    <NavLink
                                        tag={Link}
                                        to={urls.BASE_PROJECTS_URL}
                                        active={isActive(urls.BASE_PROJECTS_URL)}
                                    >
                                        Projects
                                    </NavLink>
                                )}
                            />
                            {/* <Can
                                role={props.accessLevel}
                                perform={"pricingOptions:view"}
                                yes={() => (
                                    <NavLink
                                        tag={Link}
                                        to={urls.PRICING_OPTIONS_URL}
                                        active={isActive(urls.BASE_PRICING_URL)}
                                    >
                                        Pricing
                                    </NavLink>
                                )}
                                no={() => (
                                    <Can
                                        role={props.accessLevel}
                                        perform={"addOns:view"}
                                        yes={() => (
                                            <NavLink
                                                tag={Link}
                                                to={urls.PRICING_ADDONS_URL}
                                                active={isActive(urls.BASE_PRICING_URL)}
                                            >
                                                Add-Ons
                                            </NavLink>
                                        )}
                                    />
                                )}
                            /> */}

                            {window.location.href.includes('/ops/ticketList') ?
                                <>
                                    <Can
                                        role={props.accessLevel}
                                        perform={"ops:view"}
                                        yes={() => (
                                            <NavLink
                                                tag={Link}
                                                to={urls.OPS_DATA_COLLECTION_URL}
                                            >
                                                Operations Monitoring
                                            </NavLink>
                                        )}
                                    />
                                    <Can
                                        role={props.accessLevel}
                                        perform={"ops:view"}
                                        yes={() => (
                                            <NavLink
                                                tag={Link}
                                                to={urls.OPS_TICKETLIST_URL}
                                                active={isActive(urls.BASE_OPS_URL)}
                                            >
                                                SW Ticket List
                                            </NavLink>
                                        )}
                                    /></>
                                :
                                <>
                                    {
                                        (props.globalPermissions ? props.globalPermissions.HeaderMenu_Operations:false) &&
                                        <Can
                                            role={props.accessLevel}
                                            perform={"ops:view"}
                                            yes={() => (
                                                <NavLink
                                                    tag={Link}
                                                    to={urls.OPS_DATA_COLLECTION_URL}
                                                    active={isActive(urls.BASE_OPS_URL)}

                                                >
                                                    Operations Monitoring
                                                </NavLink>
                                            )}
                                        />
                                    }

                                    {
                                        (props.globalPermissions? props.globalPermissions.HeaderMenu_SWTicketList:false) &&
                                        <Can
                                            role={props.accessLevel}
                                            perform={"ops:view"}
                                            yes={() => (
                                                <NavLink
                                                    tag={Link}
                                                    to={urls.OPS_TICKETLIST_URL}
                                                >
                                                    SW Ticket List
                                                </NavLink>
                                            )}
                                        />
                                    }

                                </>
                            }

                        </Nav>
                    </div>
                    <div className="app-header-right">
                        {
                            (props.appSumoPlanID && props.appSumoPlanID !== 'none') &&
                            <Button
                                className="mr-3 text-white badge badge-brand-green"
                                style={{ textTransform: "none" }}
                                size={"sm"}
                                color={"brand-green"}
                                onClick={() => setAppSumoOpen(!isAppSumoOpen)}
                            >
                                AppSumo Tier {props.appSumoPlanID.slice(-1)}
                            </Button>
                        }
                        <UserProfileDropdown className="ml-2" buttons={userDropdownButtons} userFullName={props.fullName} userData={userData} />
                    </div>
                </div>
            </nav>
            <AppSumoTiersModal
                data={appSumoPlans}
                isOpen={isAppSumoOpen}
                setOpen={setAppSumoOpen}
            />
        </Fragment>
    );
}

const mapStateToProps = state => {
    return {
        fullName: `${state.loggedUser.user.first_name} ${state.loggedUser.user.last_name}`,
        numCart: state.cart.length,
        numReady: state.accountOverview.landing.readyCampaigns.reduce((acc, current) => acc + current.Campaigns.length, 0),
        creditBalance: state.loggedUser.creditBalance,
        token: state.loggedUser.token,
        logged: state.loggedUser.logged,
        accessLevel: state.loggedUser.accessLevel,
        appSumoPlanID: state.loggedUser.appSumoPlanID,
        globalPermissions: state.loggedUser.globalPermissions,
    }
}

export default connect(mapStateToProps)(DashboardHeader);
