import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import GetBrandListOAmazonProductService from '../../../../../../../../../Service/DashboardServices/AssetGalleryServices/GetBrandListOAmazonProductService';
import { Button, Card, Col, Row } from 'reactstrap';
import GetFileListByCategoryService from '../../../../../../../../../Service/DashboardServices/AssetGalleryServices/GetFileListByCategoryService';
import BackButton from "../../../../.././../../../../designUtils/Logo/BackButton.svg"
import UploadAllTypeOfAssetsFile from './Components/UploadAllTypeOfAssetsFile';
import LoadingOverlay from 'react-loading-overlay';
import Loader from "react-loaders";
import ImageAndLogo from './Components/Brand/ImageAndLogo';
import ProductImageAndLogo from './Components/Product/ProductImageAndLogo';
import GetFileListBySubCategoryService from '../../../../../../../../../Service/DashboardServices/AssetGalleryServices/GetFileListBySubCategoryService';
import FetchAllCategoryAndSubCategoryService from '../../../../../../../../../Service/DashboardServices/AssetGalleryServices/FetchAllCategoryAndSubCategoryService';
import OtherProductAssets from './Components/Product/OtherProductAssets';
import UploadImagesAndOriginalFiles from './Components/UploadImagesAndOriginalFiles'
import AmazonProductLiveImages from './Components/Product/AmazonProductLiveImages';
function ProductDetails(props) {
  const [amazonProductBrandList, setAmazonProductBrandList] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [fileListOfAssets, setFileListOfAssets] = useState(false);
  const [ASIN, setAsin] = useState();
  const [ASINImage, setAsinImage] = useState();
  const [isProductSubtabs, setIsProductSubtabs] = useState(false);
  const [subCategoryId, setSubCategoryId] = useState(6)
  const [uploadImagesPage, setUploadImagesPage] = useState(false)
  const [loadScreen, setLoadScreen] = useState(false)
  const [defaultSubCatid, setdefaultSubCatid] = useState();
  const [allFileListOfAssets, setAllFileListOfAssets] = useState(false);
  const [subCategoryText, setSubCategoryText] = useState()


  const filteredProductList =
    amazonProductBrandList &&
    amazonProductBrandList.filter((row) => {
      const asin = row.asin.toLowerCase();
      const productTitle = row.productTitle.toLowerCase();

      if (row.skus != null) {
        const skus = row.skus.join("").toLowerCase();
        if (searchTerm !== "") {
          return (
            asin.includes(searchTerm.toLowerCase()) ||
            productTitle.includes(searchTerm.toLowerCase()) ||
            skus.includes(searchTerm.toLowerCase())
          );
        }
      }
      if (searchTerm !== "") {
        return (
          asin.includes(searchTerm.toLowerCase()) ||
          productTitle.includes(searchTerm.toLowerCase())
        );
      }
      else {
        return true
      }

    });

  useEffect(() => {
    FetchAllCategoryAndSubCategoryService(props.token).then((response) => {
      if (response) {
        console.log(response)
          const filteredSubCategories = response.subCategory && response.subCategory.filter((subCat) => subCat.assetCategory === props.catId);
          console.log('filteredSubCategories');
          console.log(filteredSubCategories);
          if(filteredSubCategories.length > 0)
          {
              setdefaultSubCatid(filteredSubCategories[0].id)
              setSubCategoryText(filteredSubCategories[0].subCategoryName)
              // GetFileListBySubCategory(filteredSubCategories[0].id)
              setSubCategoryId(filteredSubCategories[0].id)
              console.log(filteredSubCategories[0].subCategoryName)
          }         
          }})
    let data = {
      integerId: props.storeOverview.integerID,
    };
    GetBrandListOAmazonProductService(props.token, data).then((response) => {
      if (response) {
        setAmazonProductBrandList(response.productList);
        console.log(response);
      }
    });

  }, []);
console.log(subCategoryText)
  const setProductDetails = (product) => {
    console.log(product)
    setIsProductSubtabs(true)
    setAsin(product.asin)
    setAsinImage(product.itemImage)
    setIsInfoTabOpen(false);
    GetFileListBySubCategory(defaultSubCatid,product.asin)
  }
  const handleBackFromUploadAssets = () => {
    setUploadImagesPage(false)

  }
  const handleProductSubCategory = (tab) => {
    console.log(tab)
    setSubCategoryId(tab.id);
    setSubCategoryText(tab.subCategoryName)
    setIsInfoTabOpen(false);
}
const GetFileListBySubCategory = (id,asin) => {
  setLoadScreen(true)
    let data = {
        integerId: props.storeOverview.integerID,
        assetCategory: props.catId,
        subCategoryId: id ? id : '',
        assetIdentifier:asin?asin:''
    };
    GetFileListBySubCategoryService(props.token, data).then((response) => {
        if (response) {
            const imageFiles = response.fileList.filter(file => {
                const extension = file.fileName.toLowerCase().split('.').pop();
                return ['jpg', 'png', 'jpeg'].includes(extension);
            });
            console.log(response)
            setFileListOfAssets(imageFiles);
            setAllFileListOfAssets(response.fileList)
            console.log(response.fileList);
            // setAssetImagePage(true)
            setLoadScreen(false)
        }
    });
}

  const handleUploadAssetsUi = () => {
    setUploadImagesPage(true)
    setIsInfoTabOpen(false);
  }
  const handleBackbutton = () => {
    setIsProductSubtabs(false)
  }

  const handleBackFromUploadAssetsImages=()=>{
    setUploadImagesPage(false)
   }
  const renderUploadAssetsPage = () => {

    if (subCategoryText ==='Live amazon images' || subCategoryText === 'Product photos' || subCategoryText === 'Lifestyle photos' || subCategoryText === 'Packaging images' || subCategoryText === 'Other images') {
        return (
            <UploadImagesAndOriginalFiles
                subCategoryId={subCategoryId}
                handleBackFromUploadAssetsImages={handleBackFromUploadAssetsImages}
                assetIdentifier={ASIN}
                setFileListOfAssets={setFileListOfAssets}
                assetCategory = {props.catId} />
        )
    }

    else {
        return (
            <UploadAllTypeOfAssetsFile
                subCategoryId={subCategoryId}
                handleBackFromUploadAssets={handleBackFromUploadAssets}
                setFileListOfAssets={setFileListOfAssets}
                setAllFileListOfAssets={setAllFileListOfAssets}
                flagNumber={10}
                assetIdentifier={ASIN} 
                assetCategory = {props.catId}/>
                
                
        )
    }

}

  const [isInfoTabOpen, setIsInfoTabOpen] = useState(false)

  const handleOpenFileInfo = () => {
    setIsInfoTabOpen(!isInfoTabOpen)   
}
  return (
    <>
      {uploadImagesPage ? renderUploadAssetsPage() :
        <Row>
          {!isProductSubtabs ?
            <Col md={12}>
              <Row>
                {filteredProductList &&
                  filteredProductList.map((product) => (
                    <Col
                      style={{ cursor: 'pointer' }}
                      md={6}
                      className="p-2"
                      onClick={() => setProductDetails(product)}
                    >
                      <Card>
                        <Row key={product.id} className="p-3">
                          <Col md={2}>
                            <img
                              src={product.itemImage}
                              height={80}
                              width={80}
                            />
                          </Col>
                          <Col md={10}>
                            <p className="mb-0">
                              <b>{product.productTitle}</b>
                            </p>
                            <p className="mb-0">{product.asin}</p>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Col> :
            <>
              <Col md={12}>
                <Card className='p-2 mb-3 child-card' >
                  <Row>
                    <Col md={12}>
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex'>
                          <button
                            onClick={() => { handleBackbutton() }}
                            className="hover-btn-link back-btn ml-2 mb-3 mr-1 "  >
                            <img src={BackButton} alt="not found" />
                          </button>
                          <h5 className=''><b>{ASIN}</b></h5>
                        </div>
                        {subCategoryText !=='Live amazon images' ?
                            props.UserPermission.AssetGallery_UploadProductAssets && <Button
                              onClick={() => handleUploadAssetsUi()}
                              className='btn-background-color-Proceed-gallery  ml-2'
                            // disabled={!brand}
                            >
                              Upload Assets
                            </Button>
                          : ''}
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className='d-flex mt-1'>
                        {props.subCategory && props.subCategory.map((sub) =>
                        (<>
                          {sub.assetCategory == props.catId ?
                            <h5 className={`${sub.id === subCategoryId ? 'selected-tab-assets-tab ' : 'assets-tab-not-selected'}`}
                            onClick={() => { handleProductSubCategory(sub);  GetFileListBySubCategory(sub.id,ASIN);}}
                            >
                              <b>{sub.subCategoryName}</b>
                            </h5>
                            : ''
                          }</>)
                        )
                        }
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={12}>
             
                <LoadingOverlay
                  active={loadScreen}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: '#f1f4f6',
                      opacity: 1,
                    }),
                    content: (base) => ({
                      ...base,
                      color: '#000',
                    }),
                  }}
                  spinner={<Loader active type="ball-pulse" />}
                  text="Loading asset details..."
                >
                {subCategoryId ===6 ?
                <AmazonProductLiveImages
                subCategoryId={subCategoryId}
                handleBackFromUploadAssetsImages={handleBackFromUploadAssetsImages}
                assetIdentifier={ASIN}
                setFileListOfAssets={setFileListOfAssets}
                assetCategory = {props.catId}
                filteredProductList={filteredProductList}
                ASINImage={ASINImage}
              />
               :subCategoryId ===7 || subCategoryId ===8 || subCategoryId ===11 || subCategoryId ===12 ?
                <ProductImageAndLogo
                  // changeTabs={changeTabs}
                  subCategoryId={subCategoryId} 
                  fileListOfAssets={fileListOfAssets} 
                  assetIdentifier={ASIN}
                  assetCategory = {props.catId}
                  handleOpenFileInfo={handleOpenFileInfo}
                  isInfoTabOpen={isInfoTabOpen}
                  setIsInfoTabOpen={setIsInfoTabOpen}
                  setFileListOfAssets={setFileListOfAssets}
                  setAllFileListOfAssets={setAllFileListOfAssets}
                  allFileListOfAssets={allFileListOfAssets}
                  />
                  :
                  <OtherProductAssets
                  assetCategory = {props.catId}
                  assetIdentifier={ASIN}
                  subCategoryId={subCategoryId} 
                  fileListOfAssets={fileListOfAssets} 
                  setFileListOfAssets={setFileListOfAssets}
                  allFileListOfAssets={allFileListOfAssets}
                  setAllFileListOfAssets={setAllFileListOfAssets}
                  />}

                </LoadingOverlay>
              
                
              </Col>
            </>
          }
        </Row>

      }
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    UserPermission: state.accountOverview.store.StoreUserPermission,
    storeOverview: state.accountOverview.store.storeOverview,
  };
};
export default connect(mapStateToProps)(ProductDetails);
