import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Input, Row } from 'reactstrap';
import Right_Arrow from '../../../../../../../../../designUtils/Storeoverview/BuildBrand/BrandQuestionair/Right_Arrow.svg';
import CreateBrandProfileMainPage from '../CreateBrandProfileMainPage';
import { connect } from 'react-redux';
import PlaceholderImage from '../../../../../../../../../designUtils/Storeoverview/BuildBrand/BrandQuestionair/PlaceholderImage.svg';
import GetBrandProfileListService from '../../../../../../../../../Service/DashboardServices/QuestionnaireServices/GetBrandProfileListService';
import BackButton from "../../../../../.././../../../designUtils/Logo/BackButton.svg"
import ExistingBrandprofileLogo from '../../../../../../../../../designUtils/Logo/Existing_Brand_Profiles.svg'
function ExistBrandProfileList(props) {
    const [showCreateBrandProfile, setShowCreateBrandProfile] = useState(true);
    const [brandProlife, setBrandProfile] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [brandId,setBrandId]= useState();
    const [brand,setBrand]= useState();
    const handleCreateNewBrand = () => {
        setShowCreateBrandProfile(false);
        let data = {
            id:'New',
            brandName:''
        }
        setBrand(data)
    };
    const handleUpdateBrand = (brand) =>{
        setShowCreateBrandProfile(false);
        setBrand(brand)
    }
    useEffect(() => {
        let data = {
            integerId:props.storeOverview.integerID
        }
        GetBrandProfileListService(props.token,data).then((response) => {
            if (response) {
                setBrandProfile(response.brandList);
            }
        });
    }, []);

    const handleSearchInputChange = (e) => {
        setSearchInput(e.target.value);
    };

    const filteredBrandProfiles = brandProlife.filter((brand) =>
        brand.brandName.toLowerCase().includes(searchInput.toLowerCase())
    );
    const closeQuestionnaire = () => {
        let data = {
            integerId:props.storeOverview.integerID
        }
        GetBrandProfileListService(props.token,data).then((response) => {
            if (response) {
                setBrandProfile(response.brandList);
                setShowCreateBrandProfile(true)
            }
        });
        
    }
    const BrandProfileCard = ({ brand }) => (
        <Col md={4} className="mb-3">
            <a href="#" className="text-decoration-none" onClick={()=>handleUpdateBrand(brand)}>
                <Card className="child-card brand-card-background1 zm-in">
                    <Row className="mt-1 p-2">
                        <Col md={12} className="d-flex  flex-row bd-highlight">
                            <div className="mt-4">
                                <img height={50} width={70} src={brand.logoLink ? 'https://simpliworks-project-onboarding-dev.s3.amazonaws.com/images/static/'+brand.logoLink : PlaceholderImage} alt="Brand Logo" />
                            </div>
                            <h4 className="" style={{ fontSize: '1.3rem', marginTop: ' 35px', marginLeft: '10px' }}>
                                <b>{brand.brandName}</b>
                            </h4>
                        </Col>
                        {props.UserPermission.BrandProfiles_ManageProfile && <Col md={12} className="mt-4 ">
                            <div className="d-flex justify-content-end ">
                                <span>
                                    Manage Profile
                                </span>
                                <span className='trans'>
                                    <img className="rounded float-start ml-3 " src={Right_Arrow} alt="Right Arrow" />
                                </span>
                            </div>
                        </Col>}
                    </Row>
                </Card>
            </a>
        </Col>
    );

    return (
        <>
            {showCreateBrandProfile ? (
                <Container fluid >
                    <Row>
                        <Col md={12}>
                            <Card className="mb-3 child-card">
                                <Col md={12} className='pl-0'>
                                    <div className='d-flex justify-content-between'>
                                        <Col md={10} className=" mb-1 mt-1 p-2 d-flex   ">

                                            <Input
                                                type="text"
                                                className="form-control w-25 ml-2"
                                                placeholder="Search Brands"
                                                value={searchInput}
                                                onChange={handleSearchInputChange}
                                            />
                                        </Col>
                                        <Col md={2} className=" mb-1 mt-1 ml-3 p-2 justify-content-end">
                                            {props.UserPermission.BrandProfiles_CreateNewBrand && <Button className="btn-background-color" onClick={()=>handleCreateNewBrand()}>
                                                <i
                                                    className="lnr-plus-circle btn-icon-wrapper"
                                                    style={{ paddingBottom: '1px', marginRight: '5px' }}
                                                >
                                                </i>
                                                New Brand
                                            </Button>}
                                        </Col>
                                    </div>
                                </Col>
                                <Col md={12} className='pl-0'>
                                    <div className='d-flex ml-2 mb-3'>
                                        <button onClick={() => { props.goBackPA() }} className="hover-btn-link back-btn mr-1 "  >
                                            <img src={BackButton} alt="not found" />
                                        </button>
                                        <img src={ExistingBrandprofileLogo} alt='not found' />
                                        <h5 className='ml-2 mt-1'> Your Existing Brand Profiles</h5>
                                    </div>
                                </Col>
                            </Card>
                        </Col>
                        {props.UserPermission.BrandProfiles_List && filteredBrandProfiles.map((brand) => (
                            <BrandProfileCard key={brand.id} brand={brand} />
                        ))}
                    </Row>
                </Container>
            ) : (
                <CreateBrandProfileMainPage closeQuestionnaire={closeQuestionnaire} brand={brand}/>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        UserPermission: state.accountOverview.store.StoreUserPermission,
        token: state.loggedUser.token,
        accessLevel: state.loggedUser.accessLevel,
        status: state.loggedUser.status,
        error: state.loggedUser.error,
        newUser: state.loggedUser.newUser,
        needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
        user: state.LWA.user,
        isAuth: state.LWA.isAuth,
        enableMobileMenu: state.theme.enableMobileMenu,
        enableClosedSidebar: state.theme.enableClosedSidebar,
        storeOverview: state.accountOverview.store.storeOverview,
    };
};

export default connect(mapStateToProps)(ExistBrandProfileList);
