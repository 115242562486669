import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Card,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import GetQuestionDetailsService from "../../../../../../../../Service/DashboardServices/QuestionnaireServices/GetQuestionDetailsService";
import SubmitAnswerServices from "../../../../../../../../Service/DashboardServices/QuestionnaireServices/SubmitAnswerServices";
import GetProgressBarDataService from "../../../../../../../../Service/DashboardServices/QuestionnaireServices/GetProgressBarDataService";
import PriveTab from "../../../../../../../../designUtils/Storeoverview/BuildBrand/BrandQuestionair/Prev_Ques_Arrow.svg";
import DownloadIcon from "../../../../../../../../designUtils/Storeoverview/BuildBrand/BrandQuestionair/DownloadIcon.svg";
import AnsArrow from "../../../../../../../../designUtils/Storeoverview/BuildBrand/BrandQuestionair/AnsArrow.svg";
import backButton from "../../../../../../../../designUtils/Logo/BackButton.svg";
import ColorPalate from "./Components/ColorPalate";
import NewBrandProfiles from "../../../../../../../../designUtils/Logo/New_Brand_Profiles.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import { faCloudUploadAlt, faDownload,faFileDownload } from "@fortawesome/free-solid-svg-icons";
import CustomModal from "../../../../../../../Components/Modals/CustomModal";
import ViewFileList from "./Components/ViewFileList";
import ReactTable from "react-table";
import { faEye,faEdit,faTrash } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as FileSaver from 'file-saver';
import notify from '../../../../../../../../../src/Notifications/ToastifyActions';
import uploadAssetsService from "../../../../../../../../Service/DashboardServices/AssetGalleryServices/uploadAssetsService";
import DownloadBrandDetailsService from "../../../../../../../../Service/DashboardServices/QuestionnaireServices/DownloadBrandDetailsService";
import New_LOGO from '../../../../../../../../../src/assets/utils/images/New_LOGO.png'

function CreateBrandProfileMainPage(props) {
  const [categoryTab, setCategoryTab] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [flag, setFlag] = useState(0);
  const [questionId, setQuestionId] = useState();
  const [answerId, setAnswerId] = useState();
  const [answerText, setAnswerText] = useState();
  const [categoryID, setCategoryID] = useState();
  const [categoryTabsMappingId, setCategoryTabsMappingId] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [allQuestions, setAllQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [progress, setProgress] = useState(0);
  const [currentId, setCurrentId] = useState();
  const [currentQuestionId, setCurrentQuestionId] = useState();
  const [subCategoryCount, setSubCategoryCount] = useState([]);
  const [totalSubCategoryCount, setTotalSubCategoryCount] = useState();
  const [brand, setBrand] = useState();
  const [isPreviousFile, setIsPreviousFile] = useState(false);
  const [fileUrl, setFileUrl] = useState('')
  const [showFile, setShowFile] = useState(false)
  const [searchFileInput, setSearchFileInput] = useState("");
  const [questionnaireAnswersId, setQuestionnaireAnswersId] = useState(0)
  const [errorMassageOfFile, setErrorMassageOfFile] = useState()
  const [submitAnswerPopUp, setSubmitAnswerPopUp] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [submittedColors, setSubmittedColors] = useState('')
  const [showDownloadButton, setShowDownloadButton] = useState(false)
  const [isPreviewModeOn, setIsPreviewModeOn] = useState(false)
  const [inActiveTabCount, setInActiveTabCount] = useState([])
  const [isConfirmBoxOpen, setIsConfirmBoxOpen] = useState(false)
  const [isCheckBoxClick, setIsCheckBoxClick] = useState(false)
  const [isPreviouslyAnswered, setIsPreviouslyAnswered] = useState(false)
  // ==================
  //  simple text and calender

  const [textboxValue, setTextboxValue] = useState(
    filteredQuestions[currentQuestionIndex]
      ? filteredQuestions[currentQuestionIndex].answerObject[0]
        ? filteredQuestions[currentQuestionIndex].answerObject[0].answerText
        : ""
      : ""
  );

  // single choice
  const [answeredOptions, setAnsweredOptions] = useState(
    filteredQuestions[currentQuestionIndex]
      ? filteredQuestions[currentQuestionIndex].answerObject[0]
        ? filteredQuestions[currentQuestionIndex].answerObject[0].answerText
        : ""
      : ""
  );

  // multiple choice
  const [checkedOptions, setCheckedOptions] = useState(filteredQuestions[currentQuestionIndex]
    ? filteredQuestions[currentQuestionIndex].answerObject[0]
      ? filteredQuestions[currentQuestionIndex].answerObject[0].answerText
      : ""
    : "");
  const [checkedId, setCheckedId] = useState([]);
  const [tempraryCheckedOptions, setTempraryCheckedOptions] = useState([]);

  // file
  const [selectFile, setSelectFile] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dragging, setDragging] = useState(false);
  // ==================
  useEffect(() => {
    console.log('props.brandId')
    console.log(props.brand)
    let data = {
      category_id: 1,
      brandId: brand ? brand.id : props.brand.id,
    };
    GetQuestionDetailsService(props.token, data).then((response) => {
      if (response) {
        setCategoryTab(response.categoryTabList);
        const inActiveTabCount = response.categoryTabList.filter(tab => tab.isActive==false);
        setInActiveTabCount(inActiveTabCount)
        setAllQuestions(response.questionDetailsList)
        setFilteredQuestions(response.questionDetailsList);
        console.log(response);
      }
    });
    GetProgressBarData();
  }, []);

  const GetProgressBarData = () => {
    let data = {
      brandId: brand ? brand.id : props.brand.id,
    }
    GetProgressBarDataService(props.token, data).then((response) => {
      if (response) {
        setSubCategoryCount(response.subCategoryCount);
        setTotalSubCategoryCount(response.totalSubCategoryCount);
        console.log(response);
      }
    });
  };

  const handlePrevClick = () => {
    setFlag(0);
    setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextClick = () => {
    let data = {
      brandId: brand ? brand.id : props.brand.id,
    }
    setErrorMassageOfFile(null);
    if (flag === 0) {
      GetProgressBarDataService(props.token, data).then((response) => {
        if (response) {
          setSubCategoryCount(response.subCategoryCount);
          setTotalSubCategoryCount(response.totalSubCategoryCount);
          setTimeout(() => {
            setCurrentQuestionIndex((prevIndex) =>
              Math.min(prevIndex + 1, filteredQuestions.length - 1)
            );
          }, 300);
        }
      });

    }
  };

  const setSubmitQuestionAnswerData = (
    questionInfo,
    answer_id,
    answer_text,
    type
  ) => {
    // console.log(answer_id)
    // simple text

    if (answer_id === 0 && answer_text && type === "ShortText") {
      setFlag(1);
      setCategoryID(questionInfo.categories);
      setCategoryTabsMappingId(questionInfo.categoryTabsMapping);
      setQuestionId(questionInfo.id);
      setAnswerId(answer_id);
      setAnswerText(answer_text);
    }
    if (answer_id === 0 && answer_text && type === "SimpleText") {
      setFlag(1);
      setCategoryID(questionInfo.categories);
      setCategoryTabsMappingId(questionInfo.categoryTabsMapping);
      setQuestionId(questionInfo.id);
      setAnswerId(answer_id);
      setAnswerText(answer_text);
    }
    if (answer_id === "Colors" && answer_text && type === "Colors") {
      setFlag(1);
      setCategoryID(questionInfo.categories);
      setCategoryTabsMappingId(questionInfo.categoryTabsMapping);
      setQuestionId(questionInfo.id);
      setAnswerId(answer_id);
      setAnswerText(answer_text);
    }
    if (answer_id !== 0 && answer_text === "Dropdown" && type === "Dropdown") {
      setFlag(1);
      setCategoryID(questionInfo.categories);
      setCategoryTabsMappingId(questionInfo.categoryTabsMapping);
      setQuestionId(questionInfo.id);
      setAnswerId(answer_id);
      setAnswerText(answer_text);
    }
    //  single
    if (type === "SingleChoice") {
      setFlag(1);
      setCategoryID(questionInfo.categories);
      setCategoryTabsMappingId(questionInfo.categoryTabsMapping);
      setQuestionId(questionInfo.id);
      // setAnswerText(answer_text);
      setAnswerText("['" + answer_text + "']");
      setAnswerId(answer_id);
    }
    //  multiple
    if (type === "MultipleChoice") {
      setFlag(1);
      setCategoryID(questionInfo.categories);
      setCategoryTabsMappingId(questionInfo.categoryTabsMapping);
      setQuestionId(questionInfo.id);
      // =====================

      const isOptionChecked = tempraryCheckedOptions.includes(answer_text);
      const isIdChecked = checkedId.includes(answer_id);
      if (isOptionChecked) {
        // If checked, remove it from the array
        const updatedOptions = tempraryCheckedOptions.filter(option => option !== answer_text);
        setCheckedOptions(updatedOptions);
        setTempraryCheckedOptions(updatedOptions);
        setAnswerText(updatedOptions);
      } else {
        // If not checked, add it to the array
        const updatedOptions = [...tempraryCheckedOptions, answer_text];
        setCheckedOptions(updatedOptions);
        setTempraryCheckedOptions(updatedOptions);
        setAnswerText(updatedOptions);
      }


      if (isIdChecked) {
        // If checked, remove it from the array
        const updatedId = checkedId.filter(option => option !== answer_id);
        setCheckedId(updatedId);
        setAnswerId(updatedId.join(','));
      } else {
        // If not checked, add it to the array
        const updatedId = [...checkedId, answer_id];
        setCheckedId(updatedId);
        setAnswerId(updatedId.join(','));
      }
      // =====================
    }
    // file
    if (type === "Files") {
      // const newFiles = [...selectedFiles];
      // for (let i = 0; i < answer_text.target.files.length; i++) {
      //   newFiles.push(answer_text.target.files[i]);
      // }
      setFlag(1);
      setCategoryID(questionInfo.categories);
      setCategoryTabsMappingId(questionInfo.categoryTabsMapping);
      setQuestionId(questionInfo.id);
      setAnswerId(answer_id);
      // setSelectedFiles(newFiles);
    }
  };
  const handleSubmitAnswer = () => {
    if (flag === 0) {
      return true;
    }
    if (questionId && flag === 1) {
      // text
      if (answerId === 0 && answerText) {
        let data = {
          questionId: questionId,
          answerId: answerId,
          brandId: brand ? brand.id : props.brand.id,
          integerId: props.storeOverview.integerID,
          answerText: answerText,
          categoryID: categoryID,
          categoryTabsMappingId: categoryTabsMappingId,
          questionnaireAnswersId: questionnaireAnswersId
        };
        SubmitAnswerServices(props.token, data).then((res) => {
          if (res) {
            setFlag(0);
            setCategoryID(0);
            setCategoryTabsMappingId(0);
            setQuestionId(0);
            setAnswerId(0);
            setAnswerText(0);
            console.log(res);
            setFilteredQuestions(res.questionDetailsList);
            setAllQuestions(res.questionDetailsList)
            setBrand(res.brandId)
            setSubCategoryCount(res.progressBarData.subCategoryCount);
            setTotalSubCategoryCount(res.progressBarData.totalSubCategoryCount);
            setQuestionnaireAnswersId(0)
            setTimeout(() => {
              setCurrentQuestionIndex((prevIndex) =>
                Math.min(prevIndex + 1, filteredQuestions.length - 1)
              );
            }, 300);
          }
        });
      }
      if (answerId !== 0 && answerText && answerId !== "Files") {
        const answerIdString = answerId //Array.isArray(answerId) ? answerId.join(', ') :answerId;
        const answerTextString = answerText// Array.isArray(answerText) ? answerText.join(', ') :answerText;
        let data = {
          questionId: questionId,
          answerId: answerIdString,
          brandId: brand ? brand.id : props.brand.id,
          answerText: answerTextString,
          integerId: props.storeOverview.integerID,
          categoryID: categoryID,
          categoryTabsMappingId: categoryTabsMappingId,
          questionnaireAnswersId: questionnaireAnswersId
        };
        SubmitAnswerServices(props.token, data).then((res) => {
          if (res) {
            setFlag(0);
            setCategoryID(0);
            setCategoryTabsMappingId(0);
            setQuestionId(0);
            setAnswerId(0);
            setAnswerText(0);
            // GetProgressBarData();
            setFilteredQuestions(res.questionDetailsList);
            setAllQuestions(res.questionDetailsList)
            setBrand(res.brandId)
            setCheckedOptions('')
            setTempraryCheckedOptions([])
            setCheckedId('')
            setSubCategoryCount(res.progressBarData.subCategoryCount);
            setTotalSubCategoryCount(res.progressBarData.totalSubCategoryCount);
            setQuestionnaireAnswersId(0)
            setTimeout(() => {
              setCurrentQuestionIndex((prevIndex) =>
                Math.min(prevIndex + 1, filteredQuestions.length - 1)
              );
            }, 300);
          }
        });
      }
      // file
      if (answerId == "Files") {

        if (selectedFiles.length == 0) {
          setFlag(0);
          setCategoryID(0);
          setCategoryTabsMappingId(0);
          setAnswerId(0);
          setAnswerText(0);
          setSelectedFiles([]);
          return true
        }
        const data = new FormData();
        data.append("questionId", questionId);
        data.append("answerId", 0);
        data.append("brandId", brand ? brand.id : props.brand.id);
        data.append("answerText", "Files");
        data.append("categoryID", categoryID);
        data.append("integerId", props.storeOverview.integerID);
        data.append("categoryTabsMappingId", categoryTabsMappingId);
        data.append("questionnaireAnswersId", questionnaireAnswersId);

        for (let i = 0; i < selectedFiles.length; i++) {
          data.append("attachmentFiles", selectedFiles[i]);
        }

        SubmitAnswerServices(props.token, data).then((res) => {
          if (res) {
            setFlag(0);
            setCategoryID(0);
            setCategoryTabsMappingId(0);
            setAnswerId(0);
            setAnswerText(0);
            setSelectedFiles([]);
            // GetProgressBarData();
            setFilteredQuestions(res.questionDetailsList);
            setAllQuestions(res.questionDetailsList)
            setBrand(res.brandId)
            setSubCategoryCount(res.progressBarData.subCategoryCount);
            setTotalSubCategoryCount(res.progressBarData.totalSubCategoryCount);
            setQuestionnaireAnswersId(0)
            setTimeout(() => {
              setCurrentQuestionIndex((prevIndex) =>
                Math.min(prevIndex + 1, filteredQuestions.length - 1)
              );
            }, 300);
          }
        });
        const newData = new FormData();

        newData.append("integerId", props.storeOverview.integerID);
        newData.append("assetCategory", 1);
        newData.append("assetIdentifier", brand ? brand.brandName : props.brand.brandName);
        newData.append("notes", '');

        if (selectedFiles.length > 0) {
            // newData.append("fileType", 'Image');
            for (let i = 0; i < selectedFiles.length; i++) {
                newData.append("attachmentFiles", selectedFiles[i]);
            }
            newData.append("attachmentFiles1", 0);
            newData.append("handleLinkFile",0);
        }

        uploadAssetsService(props.token,newData).then((res)=>{
          if(res){
            console.log(res)
          }
        })
      }
    }
  };
  const handleSearchInputChange = (e) => {
    setFilteredQuestions(allQuestions);
    const input = e.target.value;
    setSearchInput(input);
    filterQuestions(input, 0);
  };
  const filterQuestions = (input, flag) => {
    const stringQuery = input.toString();
    if (flag === 1) {
      const filtered = filteredQuestions.filter((question) =>
        question.categoryTabsMapping
          .toString()
          .toLowerCase()
          .includes(stringQuery.toLowerCase())
      );
      // console.log(filtered);
      if (filtered.length > 0) {
        setCurrentQuestionIndex((prevIndex) =>
          Math.min(filtered[0].sequence, filteredQuestions.length)
        );
      } else {
        return true;
      }
    } else {
      console.log('Else');
      if (input !== '') {
        console.log('input =!');
        console.log(input);
        const filtered = filteredQuestions.filter((question) =>
          question.questionText.toLowerCase().includes(input.toLowerCase())
        );
        setFilteredQuestions(filtered);
        setCurrentQuestionIndex((prevIndex) =>
          Math.min(prevIndex, filtered.length - 1)
        );
      }
      console.log(filteredQuestions);

    }
  };

  const setProgressCount = (tabId, questionId) => {
    if (currentQuestionId != questionId) {
      const relevantSubType = subCategoryCount.find(
        (subType) => subType.categoryTabsMapping_id == tabId
      );
      if (relevantSubType) {
        // console.log('relevantSubType id' + relevantSubType)
        let count = 100 / relevantSubType.questionCount;
        setProgress(relevantSubType.attemptedQuestionCount * count);
        setCurrentId(tabId);
        setCurrentQuestionId(questionId);
      }
    }
    // console.log('after id')
  };
  const defaultAnswers = (tabId, questionId) => {
    // default saving data 
    if (currentQuestionId != questionId) {
      setTextboxValue(filteredQuestions[currentQuestionIndex]
        ? filteredQuestions[currentQuestionIndex].answerObject[0]
          ? filteredQuestions[currentQuestionIndex].answerObject[0].answerText
          : ""
        : "")
      setAnsweredOptions(filteredQuestions[currentQuestionIndex]
        ? filteredQuestions[currentQuestionIndex].answerObject[0]
          ? filteredQuestions[currentQuestionIndex].answerObject[0].answerText
          : ""
        : "")
      setCheckedOptions(filteredQuestions[currentQuestionIndex]
        ? filteredQuestions[currentQuestionIndex].answerObject[0]
          ? filteredQuestions[currentQuestionIndex].answerObject[0].answerText
          : ""
        : "")
    }
  }
  const setCategoryTabID = (input) => {
    if (input > 1 && textboxValue == '') {
      if (filteredQuestions[currentQuestionIndex] && filteredQuestions[currentQuestionIndex].id > 1) {
        filterQuestions(input, 1);
      }
      else {
        return true
      }

    }
    filterQuestions(input, 1);
  };


  // calender

  const handleDateChange = (e) => {
    const value = e.target.value;
    setTextboxValue(value);
    setSubmitQuestionAnswerData(
      filteredQuestions[currentQuestionIndex],
      0,
      value,
      "SimpleText"
    );
  };
  // file
  const allowedTypes = ['image/png', 'image/jpeg', 'image/svg+xml'];
  const handleFileChange = (e) => {
    const newFiles = [...selectedFiles];
    for (let i = 0; i < e.target.files.length; i++) {
      if (filteredQuestions[currentQuestionIndex].id === 46) {
        if (allowedTypes.includes(e.target.files[i].type)) //
        {
          newFiles.push(e.target.files[i]);
          setSelectedFiles(newFiles);
          // console.log('done')
        }
        else {
          setErrorMassageOfFile('Only JPG, png, svg and jpeg files are allowed.')
          // console.log('else')
          return true;

        }
      }
      if (filteredQuestions[currentQuestionIndex].id !== 46) {
        // console.log('other if ')
        newFiles.push(e.target.files[i]);
        setSelectedFiles(newFiles);
      }

    }
  };

  const handleRemoveFile = (file) => {
    const updatedFiles = selectedFiles.filter((f) => f !== file);
    setSelectedFiles(updatedFiles);
    setErrorMassageOfFile(null);
    if (updatedFiles.length == 0) {
      setFlag(0)
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);

    if (filteredQuestions[currentQuestionIndex].id === 46) {
      for (let i = 0; i < droppedFiles.length; i++) {
        if (allowedTypes.includes(droppedFiles[i].type)) {
          setSelectedFiles([...selectedFiles, droppedFiles[i]]);
          setSubmitQuestionAnswerData(
            filteredQuestions[currentQuestionIndex],
            "Files",
            e,
            "Files"
          );
          console.log('done');
        } else {
          setErrorMassageOfFile('Only JPG, PNG, SVG, and JPEG files are allowed.');
          console.log('else');
          return;
        }
      }
    } else {
      setSelectedFiles([...selectedFiles, ...droppedFiles]);
      setSubmitQuestionAnswerData(
        filteredQuestions[currentQuestionIndex],
        "Files",
        e,
        "Files"
      );
    }
  };
  // const deleteFile = ()=>{
  //   alert('Development for this functionality is in progress..');
  // }
  // const downloadFile = ()=>{
  //   alert('Development for this functionality is in progress..');
  // }
  const openFile = (url) => {
    const separatedPath = url.split("/static/");
    const ext = separatedPath[1].split(".")[1];
    if (ext == "xlsx" || ext == "csv" || ext == "xls" || ext == "docx") {
      setFileUrl(url)
      setShowFile(true)
      setTimeout(() => {
        setShowFile(false);
      }, 500);
    } else {
      setFileUrl(url)
      setShowFile(true)
    }

  }
  const columns = [
    {
      Header: "Date",
      width: 150,
      accessor: "createdAt",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "Assets",
      width: 350,
      accessor: "actualFileName",
      Cell: (cellInfo) => (
        <div className="d-flex  w-100">
          <p className="m-0 comment-paragraph">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "View",
      width: 111,
      accessor: "attachmentFile",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100" style={{ cursor: "pointer" }}>
          <p className="m-0 text-center" >
          <FontAwesomeIcon icon={faEye} onClick={() => openFile(cellInfo.value)} />
            {/* <FontAwesomeIcon icon={faTrash} onClick={() => deleteFile(cellInfo.value)} className='mr-3'/>
            <FontAwesomeIcon icon={faDownload} onClick={() => downloadFile(cellInfo.value)} className='mr-3'  /> */}
          </p>
        </div>
      ),
    }
  ]

  const filterAssets = filteredQuestions[currentQuestionIndex] ?
    filteredQuestions[currentQuestionIndex].fileList ?
      filteredQuestions[currentQuestionIndex].fileList.fileList.length > 0 ?
        filteredQuestions[currentQuestionIndex].fileList.fileList.filter((row) => {
          const assetsName = row.actualFileName.toLowerCase();
          if (row.questionnaireAnswers != questionnaireAnswersId) {
            setQuestionnaireAnswersId(row.questionnaireAnswers)
          }
          return assetsName.includes(searchFileInput.toLowerCase());
        })
        : []
      : []
    : [];
  if (filterAssets) {
    if (filterAssets.length == 0 && questionnaireAnswersId != filterAssets.length) {
      setQuestionnaireAnswersId(0)
      // console.log(filterAssets.length)
    }
  }
  const handleSearchChange = (e) => {
    setSearchFileInput(e.target.value);
  };
//   const handleDownload = () => {
    // const downloadData = {
    //   categoryTab,
    //   subCategoryCount,
    //   filteredQuestions
    // };
// console.log(downloadData)
//     const json = JSON.stringify(downloadData, null, 2);

//     const blob = new Blob([json], { type: 'application/json' });

//     const url = URL.createObjectURL(blob);

//     const a = document.createElement('a');
//     a.href = url;
//     a.download = 'downloaded_data.json';

//     document.body.appendChild(a);
//     a.click();

//     document.body.removeChild(a);
//     URL.revokeObjectURL(url);
//   };



const handleDownload = () => {
  let data = {
    brandId : props.brand.id,
    brandName: props.brand.brandName,
  };
  DownloadBrandDetailsService (props.token, data).then((res)=>{
    if(res){
      console.log(res.questionDetailsList)
      exportToPDFManagedProducts(res.questionDetailsList1,res.questionDetailsList2, props.brand.brandName)
      // exportToCSVManagedProducts(res.questionDetailsList, props.brand.brandName)
    }
  })
  
}

const exportToPDFManagedProducts = (pdfData,pdfData2,fileName) => {
  if (!pdfData || pdfData.length === 0) {
    console.error('PDF data is empty or not available.');
    return;
  }
  let flag = true
  if (!pdfData2 || pdfData2.length === 0) {
    console.error('PDF data is empty or not available.');
    flag = false
    // return;
  }
  const doc = new jsPDF();

  doc.setProperties({
    title: fileName || 'Downloaded Data'
  });

  // Set font size and style
  doc.setFontSize(12);

  // Define columns and rows
  const columns = Object.keys(pdfData[0]);
  const rows = pdfData.map(obj => Object.values(obj));
 
  const pageWidth = doc.internal.pageSize.getWidth();

  // Add a title
  doc.text( props.storeOverview.name+' : Brand Interview Questionnaire ', 10, 10);

    const addLogo = () => {
      const pageWidth = doc.internal.pageSize.getWidth();
      const logoWidth = 26; // Set desired width for logo
      const logoHeight = 8; // Set desired height for logo
      const logoXPosition = pageWidth - logoWidth - 10; // Align the logo to the right
      doc.addImage(New_LOGO, 'PNG', logoXPosition, 5, logoWidth, logoHeight);
    };
    addLogo();
  // Calculate column width and margins
    const columnCount = columns.length;
    // Calculate column widths (20%, 40%, 40%)
    const tableStartX = 10; // Starting X position of the table
    const tableWidth = pageWidth - 2 * tableStartX;
    const columnWidth = [0.10 * tableWidth,0.15 * tableWidth, 0.40 * tableWidth, 0.35 * tableWidth];
  
    // Column Styles: 20%, 40%, 40%
    const columnStyles = {
      0: { cellWidth: columnWidth[0] }, // First column gets 20%
      1: { cellWidth: columnWidth[1] }, // Second column gets 40%
      2: { cellWidth: columnWidth[2] }, // Third column gets 40%
      3: { cellWidth: columnWidth[3] }, // fourth column gets 40%
    };

  doc.autoTable({
    head: [columns],
    body: rows,
    startY: 20,
    margin: { left: tableStartX },
    styles: {
      overflow: 'linebreak',
      fontSize: 10,
      cellPadding: 2
    },
    columnStyles,
    didDrawPage: () => {
      // Add logo to every page
      addLogo();
    },
  });


  if(flag === true)
  {
    doc.addPage();
    doc.text('Previously Answered Questions', 10, 10);
    const columns2 = Object.keys(pdfData2[0]);
    const rows2 = pdfData2.map(obj => Object.values(obj));
    doc.autoTable({
      head: [columns2],
      body: rows2,
      startY: 20,
      margin: { left: tableStartX },
      styles: {
        overflow: 'linebreak',
        fontSize: 10,
        cellPadding: 2
      },
      columnStyles,
      didDrawPage: () => {
        // Add logo to every page
        addLogo();
      },
    });
  }


  // Save the PDF
  doc.save(props.storeOverview.name+'_BrandProfile_Questionnaire' || 'downloaded_data.pdf');
};

const exportToCSVManagedProducts = (csvData, fileName) => {
  if (!csvData) {
      notify.NotifySuccess('Task data is not available for this store.')
      return true
  }
  // let sheetName = fileName + '_Workflow'
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  console.log(csvData)
  console.log(fileName)
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { 'Brand Interview' : ws }, SheetNames: ['Brand Interview'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  // FileSaver.saveAs(data, fileName + '_BrandDetails_' + new Date() + fileExtension);
  FileSaver.saveAs(data, props.storeOverview.name+'_BrandProfile_Questionnaire'+new Date()+ fileExtension)
};



  const previewAnswers = () => {

    if (isChecked) {
      setIsConfirmBoxOpen(false);
      setIsPreviewModeOn(false)
      setIsChecked(prevState => !prevState);
    }
    else {
      if (isCheckBoxClick) {
        setIsConfirmBoxOpen(false);
        setIsPreviewModeOn(true)
        setIsChecked(prevState => !prevState);
      }
      else {
        setIsPreviewModeOn(true); 
        setIsChecked(prevState => !prevState);
      }
    }
  }
  const isLink = (text) => {
    return text.startsWith('http://') || text.startsWith('https://');
  };
  return (
    <Container fluid  id="questionnaire">
      <Row>
        <Col md={12}>
          <Card className="mb-3 card-of-problemarea">
            <Col md={12} className="pl-0">
              <div className="d-flex justify-content-between mb-1 mt-1 mr-1 p-2">
                <h5>
                  <button
                    onClick={() => {
                      props.closeQuestionnaire();
                    }}
                    className="hover-btn-link back-btn"
                  >
                    <img src={backButton} alt="not found" />
                  </button>
                  <img src={NewBrandProfiles} alt=" not found" />Brand Name : {props.brand.id !== 'New' ? <span><b>{props.brand.brandName}</b></span> : <span><b>{brand ? brand.brandName : ''}</b></span>}

                  <label className="toggle-switch ml-3">
                    <input type="checkbox" checked={isChecked} onChange={() => { previewAnswers(); }} />
                    <span className="dot"></span>
                  </label>
                  <span className="custom-label ml-1 mr-2"> Preview Mode</span>
                  {
                    isPreviewModeOn === true ?
                      <button className='ml-5 mt-1 info-button-background-none'
                          title="Download Brand Interview" 
                          onClick={() => handleDownload()}>  
                          <FontAwesomeIcon
                              icon={faDownload}
                              style={{ fontSize: "20px" }}/>
                            </button>
                      : ''
                  }
                </h5>
                {
                  isPreviewModeOn === false ? <div className="d-flex w-25">
                    <Input
                      type="text"
                      className="form-control w-100"
                      placeholder="Search Questions"
                      value={searchInput}
                      onChange={handleSearchInputChange}
                      disabled={filteredQuestions[currentQuestionIndex] && filteredQuestions[currentQuestionIndex].id == 1 ? textboxValue ? false : true : false}
                    />
                  </div>
                    : ''
                }

              </div>
            </Col>
            <Col md={12} className="p-2">
              <div className="d-flex">
                {categoryTab
                  ? categoryTab.map((cat_tab) => 
                  (<>{cat_tab.isActive == true?
                    <Row
                      onClick={() => {setCategoryTabID(cat_tab.id);setIsPreviouslyAnswered(false)}}
                      style={{ cursor: "pointer" }}
                      className={cat_tab.id == currentId && isPreviouslyAnswered == false ? "selected-tab m-0" : ""}
                    >
                      <Col md={12} className="text-center">
                        <span key={cat_tab.id}>
                          <b>{cat_tab.tab}</b>
                        </span>
                      </Col>
                      <Col md={12} className="text-center">
                        {subCategoryCount
                          ? subCategoryCount.map((count) => (
                            <span>
                              {cat_tab.id === count.categoryTabsMapping_id
                                ? count.attemptedQuestionCount +
                                " of " +
                                count.questionCount +
                                " Completed"
                                : ""}
                            </span>
                          ))
                          : ""}
                      </Col>
                    </Row>:''}</>)
                  )
                  : ""}
                  {isPreviewModeOn && inActiveTabCount.length > 0 ? <Row
                      onClick={() => setIsPreviouslyAnswered(true)}
                      style={{ cursor: "pointer" }}
                      className={isPreviouslyAnswered? "selected-tab m-0" : ""}
                    >
                      <Col md={12} className="text-center">
                        <span key={isPreviouslyAnswered} className="ml-3">
                          <b>Previously Answered</b>
                        </span>
                      </Col>
                      {/* <Col md={12} className="text-center">
                        {subCategoryCount
                          ? subCategoryCount.map((count) => (
                            <span>
                              {cat_tab.id === count.categoryTabsMapping_id
                                ? count.attemptedQuestionCount +
                                " of " +
                                count.questionCount +
                                " Completed"
                                : ""}
                            </span>
                          ))
                          : ""}
                      </Col> */}
                    </Row> : ''}
              </div>
            </Col>
          </Card>
          <Card className={`card-min-height card-of-problemarea ${isPreviewModeOn ? 'd-none' : ''}`}>
            <Row>
              <Col md={12}>
                <div className="brand-progressBar-outer">
                  <div
                    className={
                      progress == 100
                        ? "progress-bar-curve brand-progressBar"
                        : "brand-progressBar"
                    }
                    style={{ width: `${progress}%` }}
                  />
                </div>
                <div className="d-flex ml-1 mt-2">
                  {currentQuestionIndex !== 0 ? (
                    <img
                      src={PriveTab}
                      alt="Previous"
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                      onClick={() => handlePrevClick()}
                    />
                  ) : (
                    ""
                  )}
                  <span className="ml-3 mt-1">
                    {Math.floor(progress)}% Completed |{" "}
                    {categoryTab
                      ? categoryTab.map((cat_tab) =>
                        cat_tab.id == currentId ? (
                          <span key={cat_tab.id}> {cat_tab.tab}</span>
                        ) : (
                          ""
                        )
                      )
                      : ""}{" "}
                  </span>
                </div>
              </Col>
              {filteredQuestions[currentQuestionIndex] && (
                <>
                  {setProgressCount(
                    filteredQuestions[currentQuestionIndex].categoryTabsMapping,
                    filteredQuestions[currentQuestionIndex].id
                  )}
                  {defaultAnswers(
                    filteredQuestions[currentQuestionIndex].categoryTabsMapping,
                    filteredQuestions[currentQuestionIndex].id
                  )}
                  <Col
                    md={12}
                    className="pl-5 mt-4"
                    style={{ fontWeight: 500 }}
                  >
                    <h6>
                      {" "}
                      <b>
                        {" "}
                        {filteredQuestions[currentQuestionIndex].questionText} {filteredQuestions[currentQuestionIndex].id == 1 ? <span style={{ color: 'red' }}>*</span> : ''}
                      </b>
                    </h6>
                  </Col>
                  <Col md={12} className="pl-5">
                    {filteredQuestions[currentQuestionIndex].questionType ===
                      1 ? (
                      <FormGroup>
                        <textarea
                          id={`id${filteredQuestions[currentQuestionIndex].id}`}
                          name={`name${filteredQuestions[currentQuestionIndex].id}`}
                          rows={4}
                          className="w-50 mb-3"
                          value={textboxValue ? textboxValue : ""}
                          onChange={(e) => {
                            setTextboxValue(e.target.value);
                            setSubmitQuestionAnswerData(
                              filteredQuestions[currentQuestionIndex],
                              0,
                              e.target.value,
                              "SimpleText"
                            );
                          }}
                          required

                        ></textarea>
                      </FormGroup>
                    ) : filteredQuestions[currentQuestionIndex].questionType ===
                      2 ? (
                      <select
                        id="Dropdown"
                        name="Dropdown"
                        className="form-control w-50 mb-3"
                        onChange={(e) =>
                          setSubmitQuestionAnswerData(
                            filteredQuestions[currentQuestionIndex],
                            e.target.value,
                            e.target.name,
                            "Dropdown"
                          )
                        }
                      >
                        <option value="">--Select Answer --</option>
                        {filteredQuestions[currentQuestionIndex]
                          .answerOptionsForQ
                          ? filteredQuestions[
                            currentQuestionIndex
                          ].answerOptionsForQ.map((type) => (
                            <option value={type.id}>
                              {type.answerOptionText}
                            </option>
                          ))
                          : ""}
                      </select>
                    ) : filteredQuestions[currentQuestionIndex].questionType === 3 ? (
                      <>
                        <div className="">
                          <span className="">
                            {filteredQuestions[currentQuestionIndex]
                              ? filteredQuestions[
                                currentQuestionIndex
                              ].answerOptionsForQ.map((option) => (
                                <>
                                  <p className="ml-4">
                                    <Input
                                      type="radio"
                                      name="answerOption"
                                      value={option.answerOptionText}
                                      checked={answeredOptions.includes(option.answerOptionText)}
                                      onChange={(e) => {
                                        setAnsweredOptions(e.target.value);
                                        setSubmitQuestionAnswerData(
                                          filteredQuestions[
                                          currentQuestionIndex
                                          ],
                                          option.id,
                                          e.target.value,
                                          "SingleChoice"
                                        );
                                      }}
                                    />
                                    &nbsp;
                                    {option.answerOptionText}
                                  </p>
                                </>
                              ))
                              : ""}
                          </span>
                        </div>
                      </>
                    ) : filteredQuestions[currentQuestionIndex].questionType ===
                      4 ? (
                      <>
                        <FormGroup check>
                          {filteredQuestions[currentQuestionIndex]
                            ? filteredQuestions[
                              currentQuestionIndex
                            ].answerOptionsForQ.map((option) => (
                              <>
                                <p>
                                  <Input
                                    type="checkbox"
                                    name={"answerOption" + option.id}
                                    value={option.answerOptionText}
                                    checked={checkedOptions ? checkedOptions.toString().toLowerCase().includes(option.answerOptionText.toString().toLowerCase()) : ""}
                                    onChange={(e) => {
                                      setSubmitQuestionAnswerData(
                                        filteredQuestions[
                                        currentQuestionIndex
                                        ],
                                        option.id,
                                        e.target.value,
                                        "MultipleChoice"
                                      );
                                      // setAnsweredOptionsForCheckbox(option.id,option.answerOptionText,e);
                                    }}
                                  />
                                  &nbsp;
                                  {option.answerOptionText}
                                </p>
                              </>
                            ))
                            : ""}
                        </FormGroup>
                      </>
                    ) : filteredQuestions[currentQuestionIndex].questionType ===
                      5 ? (
                      <Col md={6} className="p-0">
                        <FormGroup>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <div className="input-group-text">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                              </div>
                            </InputGroupAddon>
                            <Input
                              type="date"
                              className="form-control"
                              value={textboxValue}
                              onChange={handleDateChange}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    ) : filteredQuestions[currentQuestionIndex].questionType ===
                      6 ? (
                      <Row className="mb-5 mr-0">
                        <Col
                          md={4}
                          className="p-4"
                          onDrop={handleDrop}
                          onDragOver={handleDragOver}
                          onDragEnter={handleDragEnter}
                          onDragLeave={handleDragLeave}
                        >
                          <label
                            htmlFor="fileInput"
                            className={`custom-brand-file-input text-center uploadfile ${dragging ? "dragging" : ""
                              }`}

                          >
                            <h5>
                              <FontAwesomeIcon
                                icon={faCloudUploadAlt}
                                style={{ fontSize: "30px" }}
                              />
                            </h5>
                            <h5>Drag &amp; Drop Files </h5>
                            <h5>OR</h5>
                            <input
                              type="file"
                              id="fileInput"
                              name="ticketAttachment"
                              className="custom-brand-file"
                              onChange={(e) => {
                                handleFileChange(e);
                                setSubmitQuestionAnswerData(
                                  filteredQuestions[currentQuestionIndex],
                                  "Files",
                                  e,
                                  "Files"
                                );
                              }}
                              style={{ display: "none" }}
                            />
                            <Button
                              onClick={() =>
                                document.getElementById("fileInput").click()
                              }
                              style={{ background: "#B2A1FF" }}
                            >
                              Browse Files
                            </Button>
                          </label>
                          {/* <Link onClick={() => setIsPreviousFile(true)}>
                            View Previously Uploaded Files
                          </Link> */}
                          {selectedFiles.length === 0 ? (<p style={{ color: 'red' }}>{errorMassageOfFile}</p>) : null}
                          <p style={{ fontSize: "13px" }}>
                            Attached File List
                          </p>
                          <ul style={{ color: "green", fontSize: "13px" }}>
                            {selectedFiles
                              ? selectedFiles.map((file, index) => (
                                <li key={index}>
                                  {file.name}
                                  <button
                                    className="btn"
                                    onClick={() => handleRemoveFile(file)}
                                  >
                                    <FontAwesomeIcon
                                      color="red"
                                      size="15px"
                                      icon={faWindowClose}
                                    />
                                  </button>
                                </li>
                              ))
                              : ""}
                          </ul>
                        </Col>
                        <Col md={8} className='p-2 mb-3'>
                          <div className='d-flex mb-2'>
                            <div className='mt-2 '>
                              <h6>Uploaded Files :</h6>
                            </div>
                            {/* <div className="ml-auto">
                              <Input
                                type="text"
                                className="form-control w-100"
                                placeholder="Search Assets"
                                value={searchFileInput}
                                onChange={handleSearchChange}
                              />
                            </div> */}
                          </div>
                          <ReactTable
                            data={filterAssets}
                            columns={columns}
                            className="-fixed -highlight -striped"
                            getTheadProps={() => {
                              return {
                                style: {
                                  background: "#B1FA63",
                                  overflowY: "hidden",
                                  color: "#243837",
                                },
                              };
                            }}
                            defaultPageSize={5}
                            noDataText={"No Data Found."}
                          />
                        </Col>
                      </Row>
                    ) : filteredQuestions[currentQuestionIndex].questionType ===
                      7 ? (
                      <ColorPalate
                        questionInfo={filteredQuestions[currentQuestionIndex]}
                        setSubmitQuestionAnswerData={
                          setSubmitQuestionAnswerData
                        }
                      />
                    ) : filteredQuestions[currentQuestionIndex].questionType ===
                      8 ? (
                      <FormGroup>
                        <Input
                          id={`id${filteredQuestions[currentQuestionIndex].id}`}
                          name={`name${filteredQuestions[currentQuestionIndex].id}`}
                          type="text"
                          className="w-50 mb-3"
                          value={textboxValue ? textboxValue : ""}
                          onChange={(e) => {
                            let value = e.target.value;
                            if (filteredQuestions[currentQuestionIndex].questionType === 8) {
                              value = value.slice(0, 50);
                            }
                            setTextboxValue(value);
                            setSubmitQuestionAnswerData(
                              filteredQuestions[currentQuestionIndex],
                              0,
                              value,
                              "ShortText"
                            );
                          }}
                          required
                        />
                      </FormGroup>
                    ) : (
                      ""
                    )}
                  </Col>
                </>
              )}
              <Col
                md={12}
                className="d-flex justify-content-center pb-3 prev-next"
              >
                {currentQuestionIndex === filteredQuestions.length - 1 ? (
                  <Button
                    variant="primary"
                    className="btn-background-color "
                    onClick={() => {
                      handleSubmitAnswer();
                      props.closeQuestionnaire();
                    }}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    className="btn-background-color "
                    onClick={() => {
                      handleNextClick();
                      handleSubmitAnswer();
                    }}
                    disabled={filteredQuestions[currentQuestionIndex] ? filteredQuestions[currentQuestionIndex].id == 1 ? textboxValue ? false : true : false : false}
                  >
                    Next
                  </Button>
                )}

              </Col>
            </Row>
          </Card>
          <Card
            className={`card-of-problemarea ${!isPreviewModeOn ? 'd-none' : ''}`}
            style={{ background: '#D0EFB1' }}>
            <Row className='m-3 bg-white inner-card-question'>
              {!isPreviouslyAnswered ?<Col md={12} className='p-4'>
                {
                  categoryTab.map(Tab => (
                    Tab.id == currentId ? <><h5 className="mb-3"><b>{Tab.tab} :</b></h5></> : ''
                  ))
                }
                {currentId ? filteredQuestions.map((que) => (
                  <>
                    {currentId === que.categoryTabsMapping &&
                      <>
                        <h6 className="mt-3"><b>{que.questionText}</b></h6>
                        {/* <p>{que.answerObject[0] ? que.answerObject[0].answerText : ' - '}</p> */}
                        {que.answerObject[0] ? que.answerObject[0].answerText.includes(`{'primery':`) ? submittedColors == '' ? setSubmittedColors(JSON.parse(que.answerObject[0].answerText.replace(/'/g, '"'))) : '' : '' : ''}
                        {que.answerObject[0] ? que.answerObject[0].answerText.includes(`{'primery':`) ? submittedColors != '' ?
                          <>
                            {/* primery */}
                            <div className="d-flex">
                              <p> <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" /> Primary colors : </p>
                              <div className="ml-4 d-flex">
                                <span style={{ backgroundColor: submittedColors.primery[0] }} className='color-box' />
                                <span className="mb-1"> {submittedColors.primery[0]}</span>
                              </div>
                              <div className="ml-4 d-flex">
                                <span style={{ backgroundColor: submittedColors.primery[1] }} className='color-box' />
                                <span className="mb-1"> {submittedColors.primery[0]}</span>
                              </div>
                              <div className="ml-4 d-flex">
                                <span style={{ backgroundColor: submittedColors.primery[2] }} className='color-box' />
                                <span className="mb-1"> {submittedColors.primery[0]}</span>
                              </div>
                            </div>
                            {/* secondery */}
                            <div className="d-flex">
                              <p> <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" /> Secondary colors : </p>
                              <div className="ml-2 d-flex">
                                <span style={{ backgroundColor: submittedColors.secondary[0] }} className='color-box' />
                                <span className="mb-1"> {submittedColors.primery[0]}</span>
                              </div>
                              <div className="ml-4 d-flex ">
                                <span style={{ backgroundColor: submittedColors.secondary[1] }} className='color-box' />
                                <span className="mb-1"> {submittedColors.primery[0]}</span>
                              </div>
                              <div className="ml-4 d-flex">
                                <span style={{ backgroundColor: submittedColors.secondary[2] }} className='color-box' />
                                <span className="mb-1"> {submittedColors.primery[0]}</span>
                              </div>
                            </div>
                            {/* tertiary */}
                            <div className="d-flex">
                              <p> <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" /> Tertiary colors : </p>
                              <div className="ml-4 d-flex">&nbsp;
                                <span style={{ backgroundColor: submittedColors.tertiary[0] }} className='color-box ' />
                                <span className="mb-1"> {submittedColors.primery[0]}</span>
                              </div>
                              <div className="ml-4 d-flex">
                                <span style={{ backgroundColor: submittedColors.tertiary[1] }} className='color-box' />
                                <span className="mb-1"> {submittedColors.primery[0]}</span>
                              </div>
                              <div className="ml-4 d-flex">
                                <span style={{ backgroundColor: submittedColors.tertiary[2] }} className='color-box' />
                                <span className="mb-1"> {submittedColors.primery[0]}</span>
                              </div>
                            </div>
                          </>
                          : '' : que.answerObject[0] ?
                          que.answerObject[0].answerText.includes('Files') ?
                            que.fileList ?
                              que.fileList.fileList.length > 0 ?
                                que.fileList.fileList.map((fileUrl) => (
                                  <div className="d-flex mb-1">
                                    <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" />
                                    <a className=" mb-2" href="#" onClick={() => openFile(fileUrl.attachmentFile)}>
                                      {fileUrl.actualFileName}
                                    </a>
                                  </div>
                                ))
                                : []
                              : []
                            : que.answerObject[0].answerText.includes("['") ?
                              que.answerOptionsForQ && Array.isArray(que.answerOptionsForQ) ? (
                                que.answerOptionsForQ.map((mulAns, index) => (
                                  <div>
                                    {que.answerObject.map((ansOpt) => (
                                      ansOpt.answerText.includes(mulAns.answerOptionText) ? (
                                        <p className="mb-0 ans-background">
                                          <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" />
                                        {mulAns.answerOptionText}
                                        </p>

                                      ) : (
                                        <p className="ml-5  mb-0">{mulAns.answerOptionText}</p>
                                      )
                                    ))}
                                  </div>
                                ))
                              ) : (
                                <p>-</p>
                              )
                              : que.answerObject[0] && (que.answerObject[0].answerText.startsWith('http://') || que.answerObject[0].answerText.startsWith('https://')) ? (
                                <p>
                                  <img src={AnsArrow} className='mr-2 mb-1 ml-4 ' alt="not found" />
                                  <a className='mb-1' href={que.answerObject[0].answerText} target="_blank" rel="noopener noreferrer">{que.answerObject[0].answerText}</a>
                                </p>
                              ) :
                                <p className="ans-background">
                                  <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" />
                                  {que.answerObject[0] ? que.answerObject[0].answerText : ' - '}</p> : ' - ' : <><img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" /> - </>}
                      </>
                    }
                  </>
                )) : ''}
              </Col>:
              <Col md={12} className='p-4'>
              {
                categoryTab.map(Tab => (
                  Tab.isActive == false ? <><h5 className="mb-3"><b>{Tab.tab} :</b></h5>
              {currentId ? filteredQuestions.map((que) => (
                <>
                  {Tab.id === que.categoryTabsMapping &&
                    <>
                      {/* <h6 className="mt-3"><b>{que.questionText}</b></h6> */}
                      {/* <p>{que.answerObject[0] ? que.answerObject[0].answerText : ' - '}</p> */}
                      {que.answerObject[0] ? que.answerObject[0].answerText.includes(`{'primery':`) ? submittedColors == '' ? setSubmittedColors(JSON.parse(que.answerObject[0].answerText.replace(/'/g, '"'))) : '' : '' : ''}
                      {que.answerObject[0] ? que.answerObject[0].answerText.includes(`{'primery':`) ? submittedColors != '' ?
                        <>
                          {/* primery */}
                          <h6 className="mt-3"><b>{que.questionText}</b></h6>
                          <div className="d-flex">
                            <p> <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" /> Primary colors : </p>
                            <div className="ml-4 d-flex">
                              <span style={{ backgroundColor: submittedColors.primery[0] }} className='color-box' />
                              <span className="mb-1"> {submittedColors.primery[0]}</span>
                            </div>
                            <div className="ml-4 d-flex">
                              <span style={{ backgroundColor: submittedColors.primery[1] }} className='color-box' />
                              <span className="mb-1"> {submittedColors.primery[0]}</span>
                            </div>
                            <div className="ml-4 d-flex">
                              <span style={{ backgroundColor: submittedColors.primery[2] }} className='color-box' />
                              <span className="mb-1"> {submittedColors.primery[0]}</span>
                            </div>
                          </div>
                          {/* secondery */}
                          <div className="d-flex">
                            <p> <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" /> Secondary colors : </p>
                            <div className="ml-2 d-flex">
                              <span style={{ backgroundColor: submittedColors.secondary[0] }} className='color-box' />
                              <span className="mb-1"> {submittedColors.primery[0]}</span>
                            </div>
                            <div className="ml-4 d-flex ">
                              <span style={{ backgroundColor: submittedColors.secondary[1] }} className='color-box' />
                              <span className="mb-1"> {submittedColors.primery[0]}</span>
                            </div>
                            <div className="ml-4 d-flex">
                              <span style={{ backgroundColor: submittedColors.secondary[2] }} className='color-box' />
                              <span className="mb-1"> {submittedColors.primery[0]}</span>
                            </div>
                          </div>
                          {/* tertiary */}
                          <div className="d-flex">
                            <p> <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" /> Tertiary colors : </p>
                            <div className="ml-4 d-flex">&nbsp;
                              <span style={{ backgroundColor: submittedColors.tertiary[0] }} className='color-box ' />
                              <span className="mb-1"> {submittedColors.primery[0]}</span>
                            </div>
                            <div className="ml-4 d-flex">
                              <span style={{ backgroundColor: submittedColors.tertiary[1] }} className='color-box' />
                              <span className="mb-1"> {submittedColors.primery[0]}</span>
                            </div>
                            <div className="ml-4 d-flex">
                              <span style={{ backgroundColor: submittedColors.tertiary[2] }} className='color-box' />
                              <span className="mb-1"> {submittedColors.primery[0]}</span>
                            </div>
                          </div>
                        </>
                        : '' : que.answerObject[0] ?
                        que.answerObject[0].answerText.includes('Files') ?
                          que.fileList ?
                            que.fileList.fileList.length > 0 ?
                              <>
                              <h6 className="mt-3"><b>{que.questionText}</b> </h6>
                                {que.fileList.fileList.map((fileUrl) => (
                                  <div className="d-flex mb-1">
                                  
                                    <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" />
                                    <a className=" mb-2" href="#" onClick={() => openFile(fileUrl.attachmentFile)}>
                                      {fileUrl.actualFileName}
                                    </a>
                                  </div>
                                ))}
                              </>
                              : []
                            : []
                          : que.answerObject[0].answerText.includes("['") ?
                            que.answerOptionsForQ && Array.isArray(que.answerOptionsForQ) ? (
                              <>
                              <h6 className="mt-3"><b>{que.questionText}</b> </h6>
                              {
                                que.answerOptionsForQ.map((mulAns, index) => (
                                  <div>
                                    
                                    {que.answerObject.map((ansOpt) => (
                                      ansOpt.answerText.includes(mulAns.answerOptionText) ? (
                                        <p className="mb-0 ans-background">
                                          <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" />
                                        {mulAns.answerOptionText}
                                        </p>
  
                                      ) : (
                                        <p className="ml-5  mb-0">{mulAns.answerOptionText}</p>
                                      )
                                    ))}
                                  </div>
                                ))
                              }</>
                            ) : (
                              <p></p>
                            )
                            : que.answerObject[0] && (que.answerObject[0].answerText.startsWith('http://') || que.answerObject[0].answerText.startsWith('https://')) ? (
                              <p>
                                <h6 className="mt-3"><b>{que.questionText}</b></h6>
                                <img src={AnsArrow} className='mr-2 mb-1 ml-4 ' alt="not found" />
                                <a className='mb-1' href={que.answerObject[0].answerText} target="_blank" rel="noopener noreferrer">{que.answerObject[0].answerText}</a>
                              </p>
                            ) :<><h6 className="mt-3"><b>{que.questionText}</b> </h6>
                            <p className="ans-background">
                                
                                <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" />
                                {que.answerObject[0] ? que.answerObject[0].answerText : ' '}</p> </>
                              : ' ' : ''}
                    </>
                  }
                </>
              )) : ''}
              </> : ''
                ))
              }
            </Col> }
            </Row>
          </Card>
        </Col>
      </Row>
      <CustomModal
        header={"All File List"}
        isOpen={isPreviousFile}
        toggle={() => setIsPreviousFile(!isPreviousFile)}
        size={"md"}
        // className='curve-popup'
        className="uploadedfile-card1 curve-popup"
      >

        <ViewFileList questionInfo={filteredQuestions[currentQuestionIndex]} />
      </CustomModal>
      <CustomModal
        header={"View Image/File"}
        isOpen={showFile}
        toggle={() => setShowFile(!showFile)}
        size={"lg"}
        className='curve-popup '

      >
        <Label>
          <div>
            <iframe
              src={fileUrl ? fileUrl : ''}
              title="File Display"
              width="100%"
              height="500"
            ></iframe>
          </div>
        </Label>
      </CustomModal>

      <CustomModal
        header={" Switch to Preview Mode?"}
        isOpen={isConfirmBoxOpen}
        toggle={() => { setIsConfirmBoxOpen(!isConfirmBoxOpen); setIsPreviewModeOn(false); setIsChecked(false); setIsCheckBoxClick(false); }}
        size={"md"}
        className="uploadedfile-card1 curve-popup"
      >
        <Card className='curve-popup '>
          <Row className='m-1'>
            <Col md={12} className='ml-3 mt-4'>
              <p className="mb-0">This mode is for viewing only. You cannot make changes while in this mode. To edit your answers, simply switch off this toggle.</p>
            </Col>
            <Col md={12} className='ml-3 d-flex '>
              <input type='checkbox'
                checked={isCheckBoxClick}
                onClick={() => setIsCheckBoxClick(!isCheckBoxClick)}
              />
              <p className="ml-2 mt-3">Don't ask me again</p>
            </Col>
            <Col md={12} className='mb-3 text-md-right'>
              <Button className="btn-background-color-cancle" onClick={() => { setIsPreviewModeOn(false); setIsConfirmBoxOpen(false); setIsChecked(false); setIsCheckBoxClick(false); }}>Cancel</Button>
              &nbsp;
              <Button className='btn-background-color' onClick={() => { setIsPreviewModeOn(true); setIsConfirmBoxOpen(false) }}>Switch</Button>
            </Col>
          </Row>
        </Card>
      </CustomModal>
      <CustomModal
        header={"All Submitted Questions"}
        isOpen={submitAnswerPopUp}
        toggle={() => setSubmitAnswerPopUp(!submitAnswerPopUp)}
        size={"lg"}
        // className='curve-popup'
        className="uploadedfile-card1 curve-popup"
      >
        <Card className='curve-popup'>
          {
            categoryTab.map(Tab => (
              <>
                <div className="m-3">
                  <div className="d-flex">
                    <h5><b>{Tab.tab}</b></h5>
                    {subCategoryCount && subCategoryCount.map((count) => (
                      <span className="ml-1 mt-1">
                        {Tab.id === count.categoryTabsMapping_id &&
                          <span>
                            {count.attemptedQuestionCount} of {count.questionCount} Completed
                          </span>
                        }
                      </span>
                    ))}
                  </div>
                  {filteredQuestions.map((que) => (
                    <>
                      {Tab.id === que.categoryTabsMapping &&
                        <>
                          <h5>{que.questionText}</h5>
                          <p>{que.answerObject[0] ? que.answerObject[0].answerText : ' - '}</p>
                          {/* console.log(que.fileList.fileList[0].attachmentFile) */}
                          {que.answerObject[0] ? que.answerObject[0].answerText == 'Files' ? que.fileList.fileList.map((fileUrl) => (

                            <iframe
                              src={fileUrl ? fileUrl.attachmentFile : ''}
                              title="File Display"
                              width="100%"
                              height="650"
                              className="m-2"
                            ></iframe>
                          )) : ''
                            : ' - '}
                        </>
                      }
                    </>
                  ))}
                </div>
              </>
            ))
          }



        </Card>

      </CustomModal>

    </Container>
  );
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
    storeOverview: state.accountOverview.store.storeOverview,
    UserPermission: state.accountOverview.store.StoreUserPermission,
  };
};

export default connect(mapStateToProps)(CreateBrandProfileMainPage);
